import useSWR from 'swr';
import { backendUrl } from '../shared/client';

export interface ISettings {
  processId: number;
  backgroundUrl: string | null;
  logoUrl: string | null;
  customerImprint: string | null;
  customerPrivacyPolicy: string | null;
  blockLimit: number | null;
  contactEmail: string | null;
  languages: ILanguage[];
  descriptions: IDescription[];
  reasons: IReason[];
  forceEmptyReturnShipment: boolean;
}

export interface ILanguage {
  languageId: number;
  languageCode: IsoLanguage;
  processId: number;
}

export interface IReason {
  reasonId: number;
  index: number;
  translations: ITranslation[];
}

export interface ITranslation {
  translationId: number;
  languageId: number;
  reasonId: number;
  translation: string;
  language: ILanguage;
}

export interface IDescription {
  descriptionId: number;
  text: string;
  languageId: number;
}

export interface IReturnShipment {
  lineItems: IReturnLineItem[];
}

export interface IReturnLineItem {
  lineItemId: number;
  returnReasonId: number;
  quantity: number;
}

export enum IsoLanguage {
  de = 'de',
  en = 'en',
  es = 'es',
  it = 'it',
  fr = 'fr',
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSettings = (processId: string | undefined, processIdentifier: string | null) => {
  let url = undefined;

  if (processId && processIdentifier) {
    url = new URL(`${backendUrl}/public/return/${processId}/${processIdentifier}/settings`).href;
  }

  const { data, error, isLoading, mutate } = useSWR<ISettings>(url);
  return {
    data,
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};
