import { atom, useAtom } from 'jotai';
import React, { PropsWithChildren, useEffect } from 'react';
import { ISettings, useSettings } from '../services/settings.service';
import { useParams } from 'react-router-dom';
import i18n from '../translations/i18n';

export interface IParsedUrlParams {
  processId: number | null;
  processIdentifier: string | null;
}

export const settingsAtom = atom<ISettings | null>(null);
export const urlParamsAtom = atom<IParsedUrlParams>({ processId: null, processIdentifier: null });

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUrlParams = () => useAtom(urlParamsAtom);

/**
 * Holds and initializes atoms which are shared across the app
 */
export const AtomManager: React.FC<PropsWithChildren> = ({ children }) => {
  const [urlParams, setUrlParams] = useAtom(urlParamsAtom);
  const [, setSettings] = useAtom(settingsAtom);
  const settings = useSettings(urlParams.processId?.toString(), urlParams.processIdentifier);

  // Parse url and make sure it's valid
  const { processId, processIdentifier } = useParams();
  if (processId && processIdentifier) {
    const parsedProcessId = parseInt(processId, 10);
    if (
      isFinite(parsedProcessId) &&
      parsedProcessId !== urlParams.processId &&
      processIdentifier !== urlParams.processIdentifier
    ) {
      setUrlParams({
        processId: parsedProcessId,
        processIdentifier,
      });
    }
  }

  // Set settings context. Also add descriptions and reason translations to i18n
  useEffect(() => {
    const extendTranslations = (settings: ISettings): void => {
      const languages = settings.languages;
      const reasons = settings.reasons;

      for (const language of languages) {
        const resource: Record<string, string> = {};
        const description = settings.descriptions.find((desc) => desc.languageId === language.languageId);

        resource.description = description?.text ?? '';

        for (const reason of reasons) {
          const translation = reason.translations.find((trans) => trans.languageId === language.languageId);
          const translationKey = `reason${reason.index}`;

          resource[translationKey] = translation?.translation ?? '';
        }

        i18n.addResourceBundle(language.languageCode.toLowerCase(), 'translation', resource, true, true);
      }

      if (languages.length === 1) {
        i18n.changeLanguage(languages[0].languageCode.toLowerCase());
      }
    };

    if (settings.data) {
      extendTranslations(settings.data);
      setSettings(settings.data);
    }
  }, [settings.data, setSettings]);

  return <>{children}</>;
};
